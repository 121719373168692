import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Cover from "../components/collection/Cover";
import Navbar from "../components/Navbar";
import Collections from "../components/collection/CollectionsList";
import Footer from "../components/Footer";

function Collection() {
  const { collectionName } = useParams();
  const [data, setData] = useState({});

  useEffect(() => {
    getData();
  }, [collectionName]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const getData = async () => {
    try {
      const response = await axios.get(
        `Json/collections/${collectionName}.json`
      );
      setData(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  return <div>
    <Navbar />
    <Cover data={data} />
    <Collections data={data} />
    <Footer />
  </div>;
}

export default Collection;
