import { createContext, useContext, useEffect, useState } from "react";

const DataContext = createContext();

const DataProvider = ({ children }) => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1024);
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <DataContext.Provider value={{ isWideScreen }}>
      {children}
    </DataContext.Provider>
  );
};

function useData() {
  const context = useContext(DataContext);
  return context;
}

export { useData, DataProvider };
