import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Cover from "../components/home/Cover";
import Collections from "../components/home/Collections";
import axios from "axios";
import NewArrivals from "../components/home/NewArrivals";
import Owner from "../components/home/Owner";
import Footer from "../components/Footer";


function Home() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`Json/HomePage.json`);
        setData(response.data);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };
    getData();
  }, []);


  return (
    <div className="w-full flex flex-col items-center">
      <Navbar />
      <Cover data={data?.coverSection} />
      <Collections data={data?.collectionList} />
      <NewArrivals data={data?.newArrivals} />
      <Owner data={data?.owner} />
      <Footer data={data?.owner} />
    </div>
  );
}

export default Home;
