import styles from "./home.module.css";

function Cover({ data }) {
  return (
    <div className={styles.sectionBlack}>
      <div className={styles.container}>
        <p className="font_semibold_24">Some Stories EveryDay</p>
        <div className={styles.storiesContainer}>
          {data?.stories.map((obj, i) => {
            return (
              <div key={i} className={styles.story}>
                <div className={styles.storyImageContainer}>
                  <img src={obj?.image} alt="storyThumbnail" />
                </div>
                <p className="font_normal_10">{obj?.name}</p>
              </div>
            );
          })}
        </div>
        <div className={`${styles.coverCarousel} no-scrollbar`}>
          {data?.carouselImages?.map((obj, i) => {
            return (
              <div key={i}>
                <img src={obj?.image} alt="carouselImg" />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}

export default Cover;
