import React from "react";
import styles from "./home.module.css";
import soldOut from '../../Assets/Images/soldOut.png'

function NewArrivals({ data }) {
    return <div className={styles.sectionWhite}>
        <div className={styles.container}>
            <p className='text-left w-full pl-[20px] lg:pl-0 font_semibold_32_24'>New Arrivals</p>
            <div className={`${styles.newArrivalCarousel} no-scrollbar`}>
                {
                    data?.map((obj, i) => {
                        return (
                            <div key={i} className="w-[220px] shrink-0">
                                <div className="relative">
                                    <img src={obj?.image} alt="teesImg" style={{
                                        filter: obj?.status === "sold out" ? 'grayscale(100%)' : 'grayscale(0%)'
                                    }} className="w-[220px] h-[220px] object-cover " />
                                    {obj?.status === "sold out" && <img src={soldOut} alt="soldOut" className='absolute top-[50%] left-[50%] objectCenter z-30' />}
                                </div>
                                <p className="mt-[16px] font_semibold_13">{obj?.description}</p>
                                <p
                                    style={{
                                        opacity: `${obj?.status === "sold out" ? "0.5" : "1"}`
                                    }}
                                    className="mt-[12px] font_semibold_13">{obj?.amount}</p>
                            </div>
                        )

                    })
                }
            </div>
        </div>
    </div>;
}

export default NewArrivals;
