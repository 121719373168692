import React from "react";
import styles from "./home.module.css";
import ReactPlayer from "react-player";

function Owner({ data }) {
  return (
    <div className={styles.sectionBlack}>
      <div className={styles.container}>
        <p className="w-full font_bold_24 px-[20px] lg:px-0">
          THE<span className="text-[#FF4848]">STORY</span>WEAR
        </p>
        <p className="w-full mt-[24px] font_medium_21 px-[20px] lg:px-0">
          Welcome to StoryWear, where every t-shirt tells a tale.
        </p>
        <div className="w-full lg:flex mt-[16px] px-[20px] lg:px-0">
          <div className="flex-1 flex flex-col justify-between">
            <div>
              <p className="max-w-[480px] font_normal_13">
                At StoryWear, we believe that fashion is more than just a
                statement; it’s a story waiting to be told. Each of our t-shirts
                is a canvas, intricately designed to convey unique narratives
                that inspire, provoke thought, and spark conversations. From
                tales of adventure and discovery to stories of love, resilience,
                and hope, our designs encapsulate the essence of human
                experiences.
              </p>
            </div>
            <div className="mt-[60px]">
              <p className="font_semibold_32_24">{data?.name}</p>
              <p className="mt-[10px] font_normal_16">{data?.profession}</p>
              <p className="mt-[16px] lg:mb-[100px] font_normal_13">
                {data?.description}
              </p>
            </div>
          </div>
          <div className="flex-1">
            <img src={data?.image} alt="" className="mt-[20px] lg:mt-0" />
          </div>
        </div>
        <div className="w-full">
          <p className="font_normal_16 px-[20px] lg:px-0 my-[20px]">
            Highlights
          </p>
          <div className={`${styles.ownerVideosCarousel} no-scrollbar`}>
            {data?.list?.map((obj, i) => {
              return (
                <div
                  key={i}
                  className="w-[220px] h-[150px] rounded-[16px] overflow-hidden shrink-0"
                >
                  <ReactPlayer
                    controls={true}
                    width="100%"
                    height="100%"
                    url={obj?.url}
                    style={{ objectFit: "cover" }}
                    playsinline
                    //   light
                    className="rounded-[16px] "
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Owner;
