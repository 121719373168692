import React from 'react'
import styles from './Footer.module.css'
import instaIcon from '../Assets/Images/instaIcon.svg'
import linkedinIcon from '../Assets/Images/linkedinIcon.svg'

function Footer({ data }) {
    return (
        <div className={styles.sectionBlackDark}>
            <div className={styles.container}>
                <div className='w-full flex flex-col lg:flex-row justify-between gap-[60px] lg:gap-[120px]'>
                    <div>
                        <p className='font_bold_24'>THE<span className='text-[#FF4848]'>STORY</span>WEAR</p>
                    </div>
                    <div className='flex flex-wrap gap-[60px] font_normal_16'>
                        <div>
                            <p>{data?.name}</p>
                            <p className='mt-[32px] text-[#787878]'>Instagram</p>
                            <p className='mt-[20px] text-[#787878]'>Youtube</p>
                        </div>
                        <div>
                            <p>Customer Services</p>
                            <p className='mt-[32px] text-[#787878]'>FAQ’S</p>
                            <p className='mt-[20px] text-[#787878]'>Contact Us</p>
                        </div>
                        <div>
                            <p>Legal</p>
                            <p className='mt-[32px] text-[#787878]'>Terms of Service</p>
                            <p className='mt-[20px] text-[#787878]'>Privacy Policy</p>
                        </div>
                        <div>
                            <p>Social Connection</p>
                            <div className='mt-[32px] flex gap-[16px]'>
                                <img src={instaIcon} alt="instagram" />
                                <img src={linkedinIcon} alt="linkedin" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full flex justify-between mt-[60px] font_normal_13 text-[#787878]'>
                    <p>© 2024 TheStoryWear</p>
                    <p>helpdesk@thestorywear.com</p>
                </div>
            </div>
        </div>
    )
}

export default Footer