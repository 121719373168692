import React from "react";
import styles from "./collection.module.css";
import comingSoon from '../../Assets/Images/comingSoon.png'
import soldOut from '../../Assets/Images/soldOut.png'

function Collections({ data }) {
    return <div className={styles.sectionWhite}>
        <div className={styles.container}>
            <p className="text-left w-full font_semibold_32_24 pl-[20px] lg:pl-0">
                Story Wear Collections
            </p>
            <div className={styles.listContainer}>
                {data?.products?.filter((product) => product.status !== "coming soon")?.map((obj, i) => {
                    return (
                        <div key={i} className="">
                            <div className="relative">
                                <img
                                    src={obj.image}
                                    alt="ListItem"
                                    style={{
                                        filter:
                                            obj?.status === "coming soon" || obj?.status === "sold out"
                                                ? "grayscale(100%)"
                                                : "grayscale(0%)",
                                    }}
                                    className="w-full h-full aspect-square object-cover z-10 "
                                />
                                {obj?.status === "sold out" && <img
                                    src={soldOut}
                                    alt="soldOut"
                                    className="absolute top-[50%] left-[50%] objectCenter z-30"
                                />}
                                <p className="mt-[16px] font_semibold_16">
                                    {obj?.description}
                                </p>
                                <p
                                    style={{
                                        opacity: `${obj?.status === "coming soon" || obj?.status === "sold out" ? "0.5" : "1"}`,
                                    }}
                                    className="mt-[12px] font_semibold_16"
                                >
                                    {obj?.amount}
                                </p>
                            </div>
                        </div>
                    )
                })}
            </div>
            <p className="text-left w-full font_semibold_32_24 pl-[20px] lg:pl-0 mt-[80px] lg:mt-[120px]">
                Stories Coming Soon
            </p>
            <div className={`${styles.comingSoonCarousel} no-scrollbar`}>
                {data?.products?.filter((product) => product.status === "coming soon")?.map((obj, i) => {
                    return (
                        <div key={i} className="w-[250px] shrink-0">
                            <div className="relative">
                                <img
                                    src={obj.image}
                                    alt="ListItem"
                                    style={{
                                        filter:
                                            "grayscale(100%)",
                                    }}
                                    className="w-[250px] h-[250px] object-cover z-10 "
                                />
                                <img
                                    src={comingSoon}
                                    alt="comingSoon"
                                    className="absolute top-[50%] left-[50%] objectCenter z-30"
                                />
                                <p className="mt-[16px] font_semibold_16">
                                    {obj?.description}
                                </p>
                                <p
                                    style={{
                                        opacity: `${obj?.status === "coming soon" ? "0.5" : "1"}`,
                                    }}
                                    className="mt-[12px] font_semibold_16"
                                >
                                    {obj?.amount}
                                </p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    </div>;
}

export default Collections;
