import React from 'react'
import styles from './collection.module.css'

function Cover({ data }) {
    return (
        <div className={styles.sectionBlack}>
            <div
                style={{
                    backgroundImage: `url(${data.coverImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
                className={`${styles.coverContainer} no-scrollbar`}>
                <p className='font_semibold_52_24'>{data?.title}</p>
                <div className={styles.storiesCarousel}>
                    {data?.stories?.map((obj, i) => {
                        return (
                            <div key={i} className='flex items-center'>
                                <img src={obj?.image} alt="storyImage" />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Cover