import React from "react";
import styles from "./home.module.css";
import comingSoon from "../../Assets/Images/comingSoon.png";
import moreDetailsIcon from "../../Assets/Images/moreDetailsIcon.svg";
import { useNavigate } from "react-router-dom";

function CollectionsList({ data }) {
  const navigate = useNavigate();
  return (
    <div className={styles.sectionWhite}>
      <div className={styles.collectionsContainer}>
        <p className="text-left w-full font_semibold_32_24">
          Story Wear Collections
        </p>
        <div className={styles.collectionsListContainer}>
          {data?.map((obj, i) => {
            return (
              <div key={i} className="">
                <div className="relative">
                  <img
                    src={obj.image}
                    alt="ListItem"
                    style={{
                      filter:
                        obj?.status === "coming soon"
                          ? "grayscale(100%)"
                          : "grayscale(0%)",
                    }}
                    className="w-full h-full aspect-[4/5]  object-cover z-10 "
                  />
                  <img
                    onClick={() => navigate(`/${obj?.urlParams}`)}
                    src={obj?.icon}
                    alt="listIcon"
                    className="absolute top-[12px] left-[12px] w-[52px] h-[52px] rounded-[8px] z-20 cursor-pointer"
                  />
                  {obj?.status === "coming soon" && (
                    <img
                      src={comingSoon}
                      alt="comingSoon"
                      className="absolute top-[50%] left-[50%] objectCenter z-30"
                    />
                  )}
                </div>
                <div
                  onClick={() => navigate(`/${obj?.urlParams}`)}
                  className="mt-[16px] flex items-center gap-[16px] cursor-pointer"
                >
                  <p className="font_semibold_18 opacity-[0.5]">
                    {obj?.CollectionName}
                  </p>
                  <img src={moreDetailsIcon} alt="moreDetailsIcon" />
                </div>
                <p className="mt-[16px] font_semibold_16">
                  {obj?.CollectionDescription}
                </p>
                <p
                  style={{
                    opacity: `${obj?.status === "coming soon" ? "0.5" : "1"}`,
                  }}
                  className="mt-[12px] font_semibold_16"
                >
                  {obj?.amount}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CollectionsList;
