import React from "react";
import styles from "./Navbar.module.css";
import cartIcon from "../Assets/Images/cartIcon.svg";
import { useData } from "../contexts/DataContext";
import Hamburger from "./Hamburger";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();
  const { isWideScreen } = useData();
  return (
    <div className={styles.container}>
      <p onClick={() => navigate("/")} className="font_bold_24 cursor-pointer">
        THE<span className="text-[#FF4848]">STORY</span>WEAR
      </p>
      {isWideScreen ? (
        <div className={`${styles.navbarButtons} font_semibold_16`}>
          <button>STORIES</button>
          <button>FASHION TIPS</button>
          <button>MY ACCOUNT</button>
          <button>
            <img src={cartIcon} alt="cart" />
          </button>
        </div>
      ) : (
        <div className="flex items-center gap-[16px]">
          <button>
            <img src={cartIcon} alt="cart" />
          </button>
          <Hamburger />{" "}
        </div>
      )}
    </div>
  );
}

export default Navbar;
